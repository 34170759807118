import styled from 'styled-components';
import { StyledContent as Content, StyledH1 } from 'components/Global.styles';

export const StyledContent = styled(Content)`
  padding-right: 8px;
`;

export const ImageColumn = styled.div`
  padding-left: 45px;
  margin-bottom: 60px;
  ${({ theme }) => theme.mq.mobile} {
    padding-left: 0;
  }

  img {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    ${({ theme }) => theme.mq.mobile} {
      max-width: 180px;
    }
  }
`;

export const CardContent = styled(Content)`
  p,
  ol,
  ul {
    font-size: 1.4rem;
    line-height: 1.29;
    margin-bottom: 24px;
    a {
      color: inherit;
    }
  }
`;

export const CardTitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 24px;
  height: 60px;

  ${StyledH1} {
    margin-bottom: 0;
  }
`;

export const CardImgWrapper = styled.div`
  width: 90px;
  margin-left: 16px;
`;

export const CardImg = styled.img`
  max-width: 100%;
  height: auto;
`;
