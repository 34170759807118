import React from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';

import SEO from 'layout/SEO';
import contactImage from 'assets/images/contact.png';
import cardIconLetter from 'assets/images/contact_letter.png';
import cardIconSkill from 'assets/images/skill.png';
import cardBook from 'assets/images/book.png';

import { PageWrapper, StyledH1 } from 'components/Global.styles';
import {
  StyledContent,
  ImageColumn,
  CardContent,
  CardTitleWrapper,
  CardImgWrapper,
  CardImg,
} from './PageAbout.styles';

const CardIcon = ({ iconIndex }) => {
  return (
    <CardImgWrapper>
      {iconIndex === 0 && <CardImg src={cardIconLetter} />}
      {iconIndex === 1 && <CardImg src={cardBook} />}
      {iconIndex === 2 && <CardImg src={cardIconSkill} />}
    </CardImgWrapper>
  );
};

const PageAbout = ({ location, data: { wpPage } }) => {
  const { acfAboutPage: acf, title } = wpPage;
  return (
    <>
      <SEO title={title} pathname={location.pathname} />
      <PageWrapper>
        <Container>
          <Row>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 7, order: 1 }}>
              <StyledH1 textAlign="left">{acf.pageTitle}</StyledH1>
              <StyledContent
                dangerouslySetInnerHTML={{
                  __html: acf.pageContent,
                }}
              />
            </Col>
            <Col xs={{ span: 12, order: 1 }} md={{ span: 5, order: 2 }}>
              <ImageColumn>
                <img src={contactImage} alt="Contact" />
              </ImageColumn>
            </Col>
          </Row>
          <Row id="contact">
            {acf.cards &&
              acf.cards.map((card, index) => (
                <Col xs={12} md={6} lg={4} key={card.title}>
                  <CardTitleWrapper>
                    <StyledH1 as="h2" fontSmaller marginSmaller textAlign="left">
                      {card.title}
                    </StyledH1>
                    <CardIcon iconIndex={index} />
                  </CardTitleWrapper>
                  <CardContent fontSmaller dangerouslySetInnerHTML={{ __html: card.content }} />
                </Col>
              ))}
          </Row>
        </Container>
      </PageWrapper>
    </>
  );
};

export default PageAbout;

export const query = graphql`
  query aboutPageQuery($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      slug
      databaseId
      template {
        templateName
      }
      title
      acfAboutPage {
        cards {
          content
          title
        }
        pageTitle
        pageContent
      }
    }
  }
`;
